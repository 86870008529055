<template>
  <div>
    <b-container v-if="transactionsFetched" class="mb-3">
      <b-row class="text-center">
        <h1 class=" my-3 px-3 p-sm-0 py-0 py-md-3 page-heading w-100">{{ $t('view_transactions_heading') }}</h1>
      </b-row>
      <b-card no-body >
        <b-tabs card>
          <b-tab :title="$t('bought_transactions')" v-on:click="getPaginationPurchasedTransactions(1)">
            <div v-if="!paginationPurchasedTransactionsLoad" class="mx-auto my-5">
              <b-spinner large label="Large Spinner"></b-spinner>
              <div>{{ $t('loading_transactions') }}</div>
            </div>
            <div v-else-if="paginationPurchasedTransactionsTotal > 0">
              <table class="table table-striped table-bordered add-manage-table table demo footable-loaded footable" data-filter="#filter" data-filter-text-only="true">
                <thead>
                <tr>
                  <th>{{ $t('date') }}</th>
                  <th>{{ $t('order_hash') }}</th>
                  <th>{{ $t('task') }}</th>
                  <th>{{ $t('seller') }}</th>
                  <th>{{$t('status')}}</th>
                  <th>{{ $t('price_heading') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(transaction, index) in transactionsBuyer" :key="index" class="mb-md-0 mb-3">
                  <td :data-title="$t('date')">{{date(transaction.date)}}</td>
                  <td :data-title="$t('order_hash')">
                    <router-link class="text-dark" :to="'/my-account/orders/receive/' + transaction.hash ">{{transaction.hash}}</router-link>
                  </td>
                  <td :data-title="$t('task')">{{transaction.gig_title}}</td>
                  <td :data-title="$t('seller')">{{transaction.user}}</td>
                  <td :data-title="$t('status')">
                    <span v-if="transaction.status === 6" class="p-0">{{$t('refund')}}</span>
                    <span v-else class="p-0">{{$t('sent')}}</span>
                  </td>
                  <td :data-title="$t('price_heading')">{{transaction.price}} {{transaction.currency}}</td>
                </tr>
                </tbody>
              </table>
              <Pagination :perPage="paginationPurchasedTransactionsPerPage" :currentPage="currentPurchasedTransactionsPage" :total="paginationPurchasedTransactionsTotal"
                          v-on:paginationUpdate="getPaginationPurchasedTransactions" class="mt-3"/>
            </div>
            <div v-else class="text-left">
              <p>{{ $t('transactions_empty_heading') }}</p>
            </div>
          </b-tab>
          <b-tab :title="$t('sold_transactions')" v-on:click="getPaginationSoldTransactions(1)" v-if="roleSeller">
            <div v-if="!paginationSoldTransactionsLoad" class="mx-auto my-5">
              <b-spinner large label="Large Spinner"></b-spinner>
              <div>{{ $t('loading_transactions') }}</div>
            </div>
            <div v-else-if="paginationSoldTransactionsTotal > 0">
              <table v-if="paginationSoldTransactionsLoad"  class="table table-striped table-bordered add-manage-table table demo footable-loaded footable" data-filter="#filter" data-filter-text-only="true">
                <thead>
                  <tr>
                    <th>{{ $t('date') }}</th>
                    <th>{{ $t('order_hash') }}</th>
                    <th>{{ $t('task') }}</th>
                    <th>{{ $t('buyer') }}</th>
                    <th>{{ $t('status') }}</th>
                    <th>{{ $t('price_heading') }}</th>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(transaction, index) in transactionsSeller" :key="index" class="mb-md-0 mb-3">
                  <td :data-title="$t('date')">{{date(transaction.date)}}</td>
                  <td :data-title="$t('order_hash')">
                    <router-link class="text-dark" :to="'/my-account/orders/receive/' + transaction.hash ">{{transaction.hash}}</router-link>
                  </td>
                  <td :data-title="$t('task')">{{transaction.gig_title}}</td>
                  <td :data-title="$t('buyer')">{{transaction.user}}</td>
                  <td :data-title="$t('status')">
                    <span class="p-0">{{$t('received')}}</span>
                  </td>
                  <td :data-title="$t('price_heading')">{{transaction.price}} {{transaction.currency}}</td>
                </tr>
                </tbody>
              </table>
              <Pagination :perPage="paginationSoldTransactionsPerPage" :currentPage="currentSoldTransactionsPage" :total="paginationSoldTransactionsTotal"
                          v-on:paginationUpdate="getPaginationSoldTransactions" class="mt-3"/>
            </div>
            <div v-else class="text-left">
              <p>{{ $t('transactions_empty_heading') }}</p>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-container>
    <div v-else class="mx-auto my-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>{{ $t('loading_transactions') }}</div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService.js'
import Pagination from '../../components/Global/Pagination'
export default {
  name: 'ViewTransactions',
  components: {
    Pagination
  },
  data () {
    return {
      roleSeller: localStorage.getItem('role_seller'),
      transactionsBuyer: [],
      transactionsFetched: false,
      paginationBuyerLoad: false,
      paginationSoldTransactionsLoad: false,
      paginationSoldTransactionsPerPage: 0,
      currentSoldTransactionsPage: 0,
      paginationSoldTransactionsTotal: 0,
      paginationPurchasedLoad: false,
      paginationPurchasedTransactionsLoad: true,
      paginationPurchasedTransactionsPerPage: 0,
      currentPurchasedTransactionsPage: 0,
      paginationPurchasedTransactionsTotal: 0,
      transactionsSeller: []
    }
  },
  mounted () {
    this.getPaginationPurchasedTransactions(1)
  },
  methods: {
    date (date) {
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    getPaginationSoldTransactions (page) {
      this.paginationSoldTransactionsLoad = false
      ApiService.getTransactionHistory('in', page).then(response => {
        this.transactionsSeller = response.data.data.results
        this.paginationSoldTransactionsPerPage = response.data.data.pagination.per_page
        this.paginationSoldTransactionsTotal = response.data.data.pagination.total
        this.currentSoldTransactionsPage = response.data.data.pagination.page
        this.paginationSoldTransactionsLoad = true
      }).catch(() => {
      })
    },
    getPaginationPurchasedTransactions (page) {
      this.paginationPurchasedTransactionsLoad = false
      ApiService.getTransactionHistory('out', page).then(response => {
        this.transactionsBuyer = response.data.data.results
        this.currentPurchasedTransactionsPage = response.data.data.pagination.page
        this.paginationPurchasedTransactionsPerPage = response.data.data.pagination.per_page
        this.paginationPurchasedTransactionsTotal = response.data.data.pagination.total
        this.paginationPurchasedTransactionsLoad = true
        this.transactionsFetched = true
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>
  @media only screen and (max-width: 768px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    table {
      border: none;
    }

    td {
      /* Behave like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50% !important;
      text-align: left;
      overflow-y: visible;
      font-size: 90%;
      width: unset;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 1em;
      height: 100%;
      left: 1em;
      width: 45%;
      padding-right: 10px;
      text-align: left;
      overflow-y: hidden;
      font-weight: bold;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    /*
    Label the data
    */
    td:before {
      content: attr(data-title);
    }
  }
</style>
